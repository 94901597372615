import React, { useState, useEffect } from 'react';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import Form from 'react-bootstrap/Form';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Button, FormGroup, FormLabel } from 'react-bootstrap';
import { baseUrl, access_token } from '../services/api';
import { Edit, EditAttributes, PersonPinCircle, Recycling, Remove } from '@mui/icons-material';
import axios from 'axios';
import { IconLeft } from 'react-day-picker';
import { PenFill, Trash } from 'react-bootstrap-icons';
import { Modal } from "react-bootstrap";
function AddReferralCode({ referralCodes = [] }) {
  const [refresh, setRefresh] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [showUsersModal, setShowUsersModal] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersWithRefferalCode, setUsersWithRefferalCode] = useState([]);
  useEffect(() => {
    // Fetch referral codes on component mount
    const fetchReferralCodes = async () => {
      try {
        const response = await fetch(`${baseUrl}/admin/getRefferalCode`, {
          method: 'GET',
          headers: {
            // Add any necessary headers, such as authentication headers
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },

          // You can add other options like credentials, mode, etc. if needed
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Datatatatattata Referral", data);
        setUsersWithRefferalCode(data.usersWithRefferalCode)
        setData(data);
      } catch (error) {
        console.error('Error fetching referral codes:', error.message);
      }
    };

    fetchReferralCodes();
  }, [refresh]);
  const handleStartDate = (val) => {
    setStartDate(val);
  };
  const handleEdit = (code) => {
    setSelectedUsers(code);
    setEditingId(code._id);
    setFormData({
      referalCode: code.referalCode,
      description: code.description,
      expireDate: new Date(code.expireDate).toISOString().slice(0, 16),
      period: code.period || '1 month',
      subscriptionPlan: code.subscriptionPlan || 'Freemium',
    });
  };

  const handleUpdateButtonClick = async () => {
    try {
      if (!editingId) return;
  
      // Preserve the existing expireDate if it's not being updated
      const existingItem = data.find(item => item._id === editingId);
      const updatedData = {
        ...formData,
        expireDate: formData.expireDate || existingItem?.expireDate, 
      };
  
      const response = await axios.put(
        `${baseUrl}/admin/updateReferralCode/${editingId}`,  
        {
          ...formData,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
          },
        }
      );
      
  
      if (response.status === 200) {
        alert('Referral code updated successfully!');
        setData((prevData) =>
          prevData.map((item) =>
            item._id === editingId ? { ...item, ...updatedData } : item
          )
        );
        setEditingId(null);
        setFormData({
          referalCode: '',
          description: '',
          expireDate: '',
          period: '',
          subscriptionPlan: '',
        });
      } else {
        alert('Oops! Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error updating referral code:', error.response?.data || error.message);
      alert('Oops! Something went wrong. Please try again.');
    }
  };
  

  const handleShowUsers = (users) => {
    setSelectedUsers(users);
    setUsersWithRefferalCode(users);
    setShowUsersModal(true);
  };
  const columns = [
    {
      dataField: 'referalCode',
      text: 'Referral Code',
      sort: true,
      filter: textFilter({
        placeholder: 'Search by Referral Code',
        className: 'from-control',
        delay: 1000,
        caseSensitive: false,
        style: {

          borderRadius: 5

        }
      }),


    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
    },
    {
      dataField: 'subscriptionPlan',
      text: 'Subscription Plan',
      sort: true,
    },
    {
      dataField: 'period',
      text: 'Period',
      sort: true,
    },
    {
      dataField: 'expireDate',
      text: 'Expire Date',
      formatter: (cellContent, row) => {
        const date = new Date(cellContent); // Assuming cellContent is a valid date string

        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        });

        return formattedDate;
      },
      sort: true,
    },
    {
      dataField: 'numberOfUserJoind',
      text: 'Number of Users Joined',
      sort: true,
      formatter: (cell, row) => (
        <span
          style={{ cursor: 'pointer', color: 'red', fontSize: '24px',textDecoration: 'none' }}
          onClick={() => handleShowUsers(row.usersWithRefferalCode)}
        >
          {cell}
        </span>
      ),
    },

    {
      dataField: 'toggle',
      text: 'Action',

      formatter: (cellContent, row) => (

        <div style={{ width: 'auto', marginLeft: 9, display: 'flex' }} className="custom-control custom-switch">
          <input
            style={{ flex: 1 }}
            type="checkbox"
            className="custom-control-input"
            id={`toggleSwitch${row._id}`}
            checked={row.referalCodeOn}
            onChange={() => handleToggle(row._id, row.referalCodeOn)}
          />
          <label className="custom-control-label" htmlFor={`toggleSwitch${row._id}`}>
            {row.referalCodeOn ? 'On' : 'Off'}
          </label>
          <button style={{marginLeft: '4px', flex: 1 }} className="btn btn-danger" onClick={() => handleDelete(row.referalCode)}>
            <Trash size={13} style={{ alignContent: 'center' }} />
          </button>
          <button style={{  marginLeft: '4px',flex: 1 }} className="btn btn-warning" onClick={() => handleEdit(row)}>
            <PenFill color='white' size={13} style={{ alignContent: 'center' }} />
          </button>
        </div>
      ),
    },

  ];

  const [formData, setFormData] = useState({
    referalCode: '',
    description: '',
    expireDate: '',
    period: '',
    subscriptionPlan: ''

  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Create a copy of current formData
    let updatedData = { ...formData, [name]: value };

   
    setFormData(updatedData);
  };

  const handleSaveButtonClick = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/admin/refferalCode`,
        {
          ...formData,

        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        alert('Referral code added successfully!');
        setData([...data, response.data]);
        setFormData({
          referalCode: '',
          description: '',
          expireDate: '',
          period: '',
          subscriptionPlan: ''
        });
      } else {
        alert('Oops! Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error adding referral code:', error.message);
      alert('Oops! Something went wrong. Please try again. ' + error.message);
    }
  };


  const handleDelete = async (referalCode) => {
    const confirmed = window.confirm('Are you sure you want to delete this referral code?');
    console.log(access_token)
    if (!confirmed) {
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/admin/deleteRefferalCode`,
        { referalCode: referalCode },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
          },
        }
      );

      if (response.status === 200) {
        // Data deleted successfully
        alert('Referral code deleted successfully!');
        // Update local state by removing the deleted item
        setData(data.filter(item => item.referalCode !== referalCode));
      } else {
        // Handle other response status codes or errors
        alert(`Oops! Something went wrong. Please try again. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting referral code:', error);
      alert('Oops! Something went wrong. Please try again.');
    }
  };

  const customStyles = {
    pagination: {
      backgroundColor: 'black',

      // Add any other pagination styling properties as needed
    },
    filter: {
      backgroundColor: 'black',
      // Add any other filter styling properties as needed
    },
  };
  const handleToggle = async (id, currentStatus) => {
    try {
      // Implement logic for toggling based on the id and currentStatus
      console.log(`Toggle action for referral code with id: ${id}`);

      // Call API to update the status accordingly
      const response = await axios.put(
        `${baseUrl}/admin/changeReferalCodeToActiveOrOFFLine/${id}`,
        {
          referalCodeOn: !currentStatus
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
          }
        }
      );

      if (response.status === 200) {
        alert("Referral code status updated!")
        setData((prevData) =>
          prevData.map((item) =>
            item._id === id ? { ...item, referalCodeOn: !currentStatus } : item
          )
        );
        console.log('Toggle successful');
        setRefresh(true); // Trigger a refresh after the action
      } else {
        alert("oops! please try again")
        console.error('Toggle failed:', response.statusText);
        // Handle error accordingly
      }
    } catch (error) {
      console.error('Toggle failed:', error.message);
      // Handle error accordingly
    }
  };




  return (

    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="row" style={{ border: '1.0px solid grey' }}>
              <div className="col-md-6">

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><strong>Referral Code</strong></Form.Label>
                  <Form.Control style={{ borderRadius: 10 }} type="text" readOnly={!!editingId} placeholder="Referral Code" name="referalCode" value={editingId ? selectedUsers.referalCode : formData.referalCode}
                    onChange={handleInputChange} required />
                </Form.Group>

              </div>
              <div className="col-md-6">
                <Form.Group className="mb-6" >
                  <Form.Label><strong>Desription</strong></Form.Label>
                  <textarea className='form-control' style={{ borderRadius: 10 }} type="text" name='description' value={formData.description}
                    onChange={handleInputChange} placeholder="Description"></textarea>

                </Form.Group>
              </div>

              <div className="col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label><strong>Subscription Plan</strong></Form.Label>
                  <Form.Control
                    as="select"
                    style={{ borderRadius: 10 }}
                    name="subscriptionPlan"
                    value={formData.subscriptionPlan}
                    onChange={handleInputChange}
                    required
                  >
                   
                    <option value="Freemium">Freemium</option>
                    <option value="Premium">Premium</option>
                    <option value="Platinum">Platinum</option>
                  </Form.Control>
                </Form.Group>
              </div>

              <div className="col-md-4">
                <Form.Group>
                  <Form.Label><strong>Subscription Period</strong></Form.Label>
                  <select
                    className='form-control'
                    name="period"
                    value={formData.period || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a period</option>
                    <option value="1 month">1 Month</option>
                    <option value="3 months">3 Months</option>
                    <option value="6 months">6 Months</option>
                    <option value="1 year">1 Year</option>
                    <option value="unlimited">Unlimited</option>
                  </select>
                </Form.Group>
              </div>
              <div className="col-md-3" style={{ marginLeft: 5 }}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label ><strong>Referral Expire Date</strong></Form.Label>
                  <Form.Control
                    style={{ borderRadius: 10 }}
                    type="datetime-local"
                    min={
                      new Date().toISOString().split('T')[0] +
                      'T' +
                      new Date().toLocaleTimeString('en-US', { hour12: false })
                    }
                    name="expireDate"
                    value={formData.expireDate}
                    onChange={handleInputChange}
                    required
                  
                  />
                </Form.Group>
              </div>



            </div>
            <button className="btn btn-success" onClick={editingId ? handleUpdateButtonClick : handleSaveButtonClick} style={{ margin: 5, float: 'right', width: 200, padding: 5 }}>
              {editingId ? "Update" : "Add"}
            </button>
            <div>
              <BootstrapTable
                keyField="number"
                data={data}
                columns={columns}
                pagination={paginationFactory({
                  paginationOptions: {
                    custom: true,
                    paginationClass: 'custom-pagination-class',
                  },
                  ...customStyles,
                })}
                filter={filterFactory({
                  ...customStyles,
                })}
              />
            </div>
          </div>
        </main>

      </div>
      <Modal show={showUsersModal} onHide={() => setShowUsersModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Users Joined</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <ul>
      {usersWithRefferalCode?.length > 0 ? (
        usersWithRefferalCode.map((user) => (
          <li key={user._id} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <img
              src={user.userInfo?.userProfileUrl ||"https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg"} 
              alt={user.userInfo?.firstName || "User"}
              style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "10px", objectFit: "cover" }}
            />
            <div>
              <p><strong>{user.userInfo?.firstName} {user.userInfo?.lastName}</strong></p>
            </div>
          </li>
        ))
      ) : (
        <p>No users found.</p>
      )}
    </ul>
  </Modal.Body>
  <Modal.Footer>
    <button className='btn-primery' onClick={() => setShowUsersModal(false)}>Close</button>
  </Modal.Footer>
</Modal>

    </div>
  );
}

export default AddReferralCode;
